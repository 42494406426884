import {
  Configuration,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import {
  Icon,
  mergeStyles,
  PrimaryButton,
  ThemeProvider,
} from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import React, { useState } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import "./index.css";
import { darkTheme, lightTheme } from "./themes";

initializeIcons();

const config: Configuration = {
  auth: {
    clientId: "73026925-c2aa-4e81-bbaa-2049f11098e1",
    authority:
      "https://login.microsoftonline.com/fad42abb-dfda-43f0-9120-b18e6e86169d",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const ErrorComponent = ({ error }: FallbackProps) => (
  <pre>{JSON.stringify(error, null, 2)}</pre>
);

const iconClass = mergeStyles({
  fontSize: 100,
  height: 100,
  width: 100,
  margin: "0 25px",
  color: "green",
});

const mainClass = mergeStyles({
  maxWidth: 400,
  margin: "auto",
  marginTop: 100,
  textAlign: "center",
});

export const App: React.FunctionComponent = () => {
  const instance = new PublicClientApplication(config);
  const [useDarkMode] = useState<boolean>(true);

  return (
    <ThemeProvider applyTo="body" theme={useDarkMode ? darkTheme : lightTheme}>
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <MsalProvider instance={instance}>
          <div className={mainClass}>
            <AuthenticatedTemplate>
              <Icon iconName="SkypeCheck" className={iconClass} />
              <h2>Device Compliant!</h2>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <LogonPage />
            </UnauthenticatedTemplate>
          </div>
        </MsalProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const LogonPage: React.FC = () => {
  const { login } = useMsalAuthentication(InteractionType.Redirect);
  return <PrimaryButton onClick={() => login()}>Check Device</PrimaryButton>;
};
